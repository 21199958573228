import React, { useState, useEffect } from "react";
// import { makeStyles } from "@mui/styles";
import {
    CssBaseline,
    Checkbox,
    Dialog,
    ListItemButton,
    Rating,
    TextField,
    Typography,
    Button, IconButton,
    Pagination,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
    Switch,
    FormControlLabel
} from "@mui/material";
import AndroidIcon from "@mui/icons-material/Android";
import IOSIcon from "@mui/icons-material/Apple";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import StoreIcon from "@mui/icons-material/Shop";
import LinkIcon from "@mui/icons-material/Link"
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import { get } from 'lodash';
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { group } from "console";

const API = "https://adsnetwork-api.romancenovelx.com";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const useStyles = makeStyles({
//     main: {
//         flexGrow: 1,
//     },
// });

export default function AdsGroup() {

    const [groups, setGroups] = useState([]);
    const [currentGroup, setCurrentGroup] = useState({
        _id: "",
        name: "",
        url: "",
        storeAppId: "",
        type: "",
        isDefault: false,
        enable: true,
        frequencyLimit: 0
    });

    const [tempGroupData, setTempGroupData] = useState({
        _id: "",
        name: "",
        url: "",
        storeAppId: "",
        type: "",
        isDefault: false,
        enable: true,
        frequencyLimit: 0
    });

    const [openDeleteApp, setOpenDeleteApp] = React.useState(false);
    const [openDeleteGroup, setOpenDeleteGroup] = React.useState(false);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [openEditApp, setOpenEditApp] = React.useState(false);
    const [openEditGroup, setOpenEditGroup] = React.useState(false);
    const [openAddGroup, setOpenAddGroup] = React.useState(false);
    const [openAddFromStore, setOpenAddFromStore] = React.useState(false);
    const [openAddFromPackage, setOpenAddFromPackage] = React.useState(false);
    // const [openAddManual, setOpenAddManual] = React.useState(false);
    const [deleteAppId, setDeleteAppId] = useState("");
    const [url, setUrl] = useState("");
    const [apps, setApps] = useState([]);
    const [currentApp, setCurrentApp] = React.useState({
        _id: "",
        link: "",
        image: "",
        title: "App",
        rating: 0,
        totalRate: 0,
        description: "",
        offerwallDescription: "Install this app to get coin",
        offerwallBonus: 1000,
        offerwallBonusUnit: "coin",
    });

    const [tempAppData, setTempAppData] = React.useState({
        _id: "",
        link: "",
        image: "",
        title: "App",
        rating: 0,
        totalRate: 0,
        description: "",
        offerwallDescription: "Install this app to get coin",
        offerwallBonus: 1000,
        offerwallBonusUnit: "coin",
    });

    const [tempStoreData, setTempStoreData] = React.useState([]);

    const updateTempStoreItem = (storeAppId: any, updateField: any, updateValue: any) => {
        var index = tempStoreData.findIndex((x: any) => x.storeAppId === storeAppId);

        if (index === -1) {
            // handle error
            console.log('no match')
        }
        else {
            let g: any = tempStoreData[index];
            g[updateField] = updateValue;
            const newStoreData: any = [
                ...tempStoreData.slice(0, index),
                g,
                ...tempStoreData.slice(index + 1)
            ]
            setTempStoreData(newStoreData);
        }
    }

    async function getAllGroup() {
        const data = await axios.get(API + "/api/v1/groups");
        setGroups(data.data);
    }

    async function getAllAppOfStore() {
        try {
            if (currentGroup.type === 'android') {
                const data = await axios.get(API + "/api/v1/fetchPlayStore", { params: { url } });
                const apps = data.data;
                for (const app of apps) app.checked = false;
                console.log(apps);
                setTempStoreData(apps);
            } else {
                const data = await axios.get(API + "/api/v1/fetchAppStore", { params: { url } });
                const apps = data.data;
                for (const app of apps) app.checked = false;
                setTempStoreData(apps);
            }
        } catch (e) {
            setTempStoreData([]);
        }
    }

    async function getAppDataFromPackage() {
        try {
            if (currentGroup.type === 'android') {
                const data = await axios.get(API + "/api/v1/fetchAndroidApp", { params: { url } });
                return data.data;
            } else {
                const data = await axios.get(API + "/api/v1/fetchIOSApp", { params: { url } });
                return data.data;
            }
        } catch (e) {
            return [];
        }
    }

    const deleteApp = async () => {
        await axios.delete(
            API + "/api/v1/groups/" +
            currentGroup._id +
            "/apps/" +
            deleteAppId
        );
        setOpenDeleteApp(false);
        await getAllApp();
    };

    const deleteGroup = async () => {
        await axios.delete(
            API + "/api/v1/groups/" + currentGroup._id
        );
        setOpenDeleteGroup(false);
        await getAllGroup();
    };

    const updateGroup = async () => {
        let data = { name: tempGroupData.name, frequencyLimit: tempGroupData.frequencyLimit, enable: tempGroupData.enable };
        await axios({
            method: 'put',
            url: API + "/api/v1/groups/" + currentGroup._id,
            data
        });
        setOpenEditGroup(false);
        await getAllGroup();
    };

    const [openChange, setOpenChange] = React.useState(false);

    const handleChange = (group: any) => () => {
        setTempGroupData(group);
        setOpenChange(true);
    };

    const handleChangeClose = () => {
        setOpenChange(false);
    };

    const changeGroupEnableStatus = async () => {
        let data = { name: tempGroupData.name, frequencyLimit: tempGroupData.frequencyLimit, enable: !tempGroupData.enable };
        await axios({
            method: 'put',
            url: API + "/api/v1/groups/" + currentGroup._id,
            data
        });
        setOpenChange(false);
        await getAllGroup();
    };

    const addGroup = async () => {
        await axios({
            method: 'post',
            url: API + "/api/v1/groups",
            data: {
                name: tempGroupData.name,
                url: tempGroupData.url
            }
        });
        setOpenAddGroup(false);
        await getAllGroup();
    };

    const updateApp = async () => {
        await axios({
            method: 'put',
            url: API + "/api/v1/groups/" + currentGroup._id + "/apps/" + currentApp._id,
            data: {
                image: tempAppData.image,
                title: tempAppData.title,
                rating: tempAppData.rating,
                totalRate: tempAppData.totalRate,
                description: tempAppData.description,
                offerwallDescription: tempAppData.offerwallDescription,
                offerwallBonus: tempAppData.offerwallBonus,
                offerwallBonusUnit: tempAppData.offerwallBonusUnit,
            }
        });
        setOpenEditApp(false);
        await getAllApp();
    };

    const addAppsFromStore = async () => {
        const data: any = [];
        for (const app of tempStoreData) {
            if (get(app, 'checked') === true) {
                const newApp: any = app;
                delete newApp.checked;
                data.push(newApp);
            }
        }

        await axios({
            method: 'post',
            url: API + "/api/v1/groups/" + currentGroup._id + "/apps",
            data
        });
        setOpenAddFromStore(false);
        await getAllApp();
    };

    const addAppsFromPackage = async () => {
        const data = await getAppDataFromPackage();

        await axios({
            method: 'post',
            url: API + "/api/v1/groups/" + currentGroup._id + "/apps",
            data
        });
        setOpenAddFromStore(false);
        await getAllApp();
    };

    const getAllApp = async () => {
        if (currentGroup._id !== "") {
            const data = await axios.get(
                API + "/api/v1/groups/" + currentGroup._id
            );
            setApps(data.data.apps);
        }
    };

    useEffect(() => {
        getAllGroup();
        getAllApp();
    }, [currentGroup]);

    return (
        <React.Fragment>
            <CssBaseline />
            <Pagination count={1} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                            Ads groups
                        </Typography>
                    </div>
                    <List sx={{ backgroundColor: '#eeeeee' }}>
                        {groups.map((group: any) => {
                            return (
                                <ListItem
                                    key={group._id}
                                    selected={group._id === currentGroup._id}
                                    secondaryAction={
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {group.isDefault !== true ? <Tooltip title="Delete group">
                                                <IconButton edge="end" aria-label="delete"
                                                    onClick={() => {
                                                        setCurrentGroup(group);
                                                        setTempGroupData(group);
                                                        setOpenDeleteGroup(true);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip> : <div />}
                                            {group.isDefault !== true ? <Tooltip title="Enable ads">
                                                <Switch
                                                    edge="end"
                                                    onChange={handleChange(group)}
                                                    checked={group.enable}
                                                    inputProps={{
                                                        'aria-labelledby': 'switch-list-label-wifi',
                                                    }}
                                                />
                                            </Tooltip> : <div />}
                                            <Tooltip title="Edit">
                                                <IconButton edge="end" aria-label="edit"
                                                    onClick={() => setOpenEditGroup(true)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>}
                                    onClick={() => {
                                        setCurrentGroup(group);
                                        setTempGroupData(group);
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Tooltip title="View app">
                                            <Avatar
                                                style={{ backgroundColor: group.isDefault === false ? '#bdbdbd' : group.type === 'android' ? '#43a047' : '#039be5' }}
                                                onClick={() => window.open(group.url, "_blank")}
                                            >
                                                {group.type === 'android' ? <AndroidIcon /> : <IOSIcon />}
                                            </Avatar>
                                        </Tooltip>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={group.isDefault === false ? group.name : <b>{group.name}</b>}
                                        secondary={
                                            <React.Fragment>
                                                <p style={{ textAlign: "justify", fontSize: 10, color: 'gray', fontStyle: 'italic' }}>
                                                    {group.storeAppId}
                                                </p>
                                                <p style={{ textAlign: "justify", fontSize: 12, color: 'black' }}>
                                                    {group.frequencyLimit === 0 ? 'Not limit' : 'Frequency limit: ' + group.frequencyLimit + 's'}
                                                </p>
                                            </React.Fragment>
                                        } />
                                </ListItem>
                            );
                        })}
                    </List>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                        <IconButton
                            sx={{ backgroundColor: 'gray', width: 30, height: 30 }}
                            onClick={() => {
                                // console.log('openAddGroup =', openAddGroup);
                                setTempGroupData({ ...currentGroup, _id: '', name: '', url: '' });
                                setOpenAddGroup(true);
                            }}
                        >
                            <AddIcon sx={{ color: '#fff' }} />
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={12} md={8}>
                    {currentGroup._id === "" ? null : (
                        <div
                            style={{
                                flex: 1,
                                padding: 10,
                                margin: 10,
                                backgroundColor: "#eeeeee",
                            }}
                        >
                            <Snackbar
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                open={openSnackBar}
                                autoHideDuration={3000}
                                onClose={() => setOpenSnackBar(false)}
                            >
                                <Alert
                                    onClose={() => setOpenSnackBar(false)}
                                    severity="success"
                                    sx={{ width: "100%" }}
                                >
                                    Copied JSON url to clipboard!
                                </Alert>
                            </Snackbar>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography variant="h6">{currentGroup.name}</Typography>
                                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            navigator.clipboard.writeText(API + '/api/v1/adsGroup?storeAppId=' + currentGroup.storeAppId);
                                            setOpenSnackBar(true)
                                        }}
                                    >
                                        <CopyIcon />
                                    </IconButton>
                                    <Typography variant="caption" style={{ marginRight: 10 }}>Copy JSON url</Typography>
                                </div>
                            </div>
                            <div
                                style={{
                                    height: 1,
                                    backgroundColor: "#cccccc",
                                    alignSelf: "center",
                                    margin: 5,
                                }}
                            />
                            {apps.map((app: any) => (
                                <div style={{ backgroundColor: '#ffffff', paddingTop: 2, marginBottom: 10 }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            margin: 5,
                                            padding: 5,
                                        }}
                                        key={app._id}
                                    >
                                        <img
                                            src={app.image} alt={'img_' + app._id}
                                            style={{ width: 50, height: 50, margin: 5 }}
                                        />

                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <b style={{ display: 'flex' }}>{app.title}</b>
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <Rating name="read-only" value={app.rating} readOnly />
                                                        <Typography variant="caption">
                                                            ({app.totalRate})
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <p style={{ textAlign: "justify", fontSize: 10, color: 'gray', fontStyle: 'italic' }}>
                                                    {app.offerwallDescription}
                                                </p>
                                            </div>
                                            <div style={{ width: 100, alignItems: 'center' }}>
                                                <div style={{ backgroundColor: '#80cbc4', borderRadius: 5, padding: 5, margin: 5, alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography variant='subtitle1' style={{ color: '#fff', display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>{app.offerwallBonus} {app.offerwallBonusUnit}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p style={{ textAlign: "justify", fontSize: 12, color: '#000', marginLeft: 10, marginRight: 10 }}>
                                        {app.description}
                                    </p>
                                    <div>
                                        <div
                                            style={{
                                                height: 1,
                                                backgroundColor: "#cccccc",
                                                margin: 5,
                                            }}
                                        />
                                        <div
                                            style={{ display: "flex", flexDirection: "row-reverse" }}
                                        >
                                            <IconButton
                                                size="small"
                                                color="secondary"
                                                onClick={() => {
                                                    setOpenDeleteApp(true);
                                                    setDeleteAppId(app._id);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => {
                                                    setCurrentApp(app);
                                                    setTempAppData(app);
                                                    setOpenEditApp(true);
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>

                            ))}
                            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: 5,
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        style={{ alignSelf: "center" }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                    <Typography variant="caption">Normal add</Typography>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: 5,
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        style={{ alignSelf: "center" }}
                                        onClick={() => {
                                            setUrl('');
                                            setOpenAddFromPackage(true);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                    <Typography variant="caption">From packageName</Typography>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        margin: 5,
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        color="default"
                                        sx={{ alignSelf: "center" }}
                                        onClick={() => {
                                            setUrl('');
                                            setTempStoreData([]);
                                            setOpenAddFromStore(true);
                                        }}
                                    >
                                        <StoreIcon />
                                    </IconButton>
                                    <Typography variant="caption">From store</Typography>
                                </div>
                            </div>

                            {/* Delete app dialog */}
                            <Dialog
                                open={openDeleteApp}
                                onClose={() => setOpenDeleteApp(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Delete confirm"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure delete this app from ads group?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            setOpenDeleteApp(false);
                                            setDeleteAppId("");
                                        }}
                                    >
                                        No
                                    </Button>
                                    <Button onClick={deleteApp} autoFocus>
                                        Yes
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {/* Edit app dialog */}
                            <Dialog
                                open={openEditApp}
                                fullWidth={true}
                                onClose={() => setOpenEditApp(false)}
                            >
                                <DialogTitle>Edit</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>Edit app detail</DialogContentText>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <img
                                            src={tempAppData.image} alt={'img_' + tempAppData._id}
                                            style={{ width: 50, height: 50, margin: 5 }}
                                        />
                                        <TextField
                                            margin="dense"
                                            id="edit_img"
                                            label="Image URL"
                                            type="url"
                                            fullWidth
                                            value={tempAppData.image}
                                            variant="standard"
                                            onChange={(e) => setTempAppData({ ...tempAppData, image: e.target.value })}
                                        />
                                    </div>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="edit_title"
                                        label="Title"
                                        fullWidth
                                        value={tempAppData.title}
                                        variant="standard"
                                        onChange={(e) => setTempAppData({ ...tempAppData, title: e.target.value })}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="edit_rating"
                                        label="Average rate"
                                        type="number"
                                        fullWidth
                                        value={tempAppData.rating}
                                        variant="standard"
                                        onChange={(e) => setTempAppData({ ...tempAppData, rating: e.target.value !== '' ? parseFloat(e.target.value) : 0 })}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="edit_totalRate"
                                        label="Total rate"
                                        type="number"
                                        fullWidth
                                        value={tempAppData.totalRate}
                                        variant="standard"
                                        onChange={(e) => setTempAppData({ ...tempAppData, totalRate: e.target.value !== '' ? parseFloat(e.target.value) : 0 })}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="edit_description"
                                        label="Description"
                                        fullWidth
                                        value={tempAppData.description}
                                        variant="standard"
                                        onChange={(e) => setTempAppData({ ...tempAppData, description: e.target.value })}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="edit_offerwalldescription"
                                        label="Offerwall description"
                                        fullWidth
                                        value={tempAppData.offerwallDescription}
                                        variant="standard"
                                        onChange={(e) => setTempAppData({ ...tempAppData, offerwallDescription: e.target.value })}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="edit_offerwallbonus"
                                        label="Offerwall bonus"
                                        type="number"
                                        fullWidth
                                        value={tempAppData.offerwallBonus}
                                        variant="standard"
                                        onChange={(e) => setTempAppData({ ...tempAppData, offerwallBonus: e.target.value !== '' ? parseInt(e.target.value) : 0 })}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="edit_offerwallBonusUnit"
                                        label="Offerwall unit"
                                        fullWidth
                                        value={tempAppData.offerwallBonusUnit}
                                        variant="standard"
                                        onChange={(e) => setTempAppData({ ...tempAppData, offerwallBonusUnit: e.target.value })}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpenEditApp(false)}>Cancel</Button>
                                    <Button onClick={updateApp}
                                        disabled={tempAppData.image === '' || tempAppData.title === ''
                                            || tempAppData.rating <= 0 || tempAppData.rating > 5
                                            || tempAppData.totalRate === 0 || tempAppData.description === ''
                                            || tempAppData.offerwallDescription === ''
                                            || tempAppData.offerwallBonus === 0
                                            || tempAppData.offerwallBonusUnit === ''}
                                    >Update</Button>
                                </DialogActions>
                            </Dialog>

                            {/* Add app from store dialog */}
                            <Dialog
                                open={openAddFromStore}
                                fullWidth={true}
                                onClose={() => setOpenAddFromStore(false)}
                            >
                                <DialogTitle>Add apps</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>Fill developer id to below textbox</DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label={"Enter " + currentGroup.type + " url"}
                                        fullWidth
                                        value={url}
                                        variant="standard"
                                        type="url"
                                        onChange={(event) => setUrl(event.target.value)}
                                    />
                                    <Button
                                        variant="contained" disabled={url === '' || (currentGroup.type === 'android' && !url.includes('play.google.com')) || (currentGroup.type === 'ios' && !url.includes('apps.apple.com'))}
                                        onClick={getAllAppOfStore}
                                    >
                                        Get store apps
                                    </Button>
                                    <List sx={{ overflow: 'visible' }}>
                                        {tempStoreData.map((app: any) => {
                                            return (
                                                <ListItem
                                                    key={app.storeAppId}
                                                    secondaryAction={
                                                        <Checkbox
                                                            edge="end"
                                                            onChange={(e) => updateTempStoreItem(app.storeAppId, 'checked', e.target.checked)}
                                                            checked={app.checked}
                                                        />
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton>
                                                        <ListItemAvatar sx={{ marginBottom: 10 }}>
                                                            <Avatar
                                                                alt={'avatar_' + app.storeAppId}
                                                                src={app.image}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText id={'label_' + app.storeAppId} primary={app.title} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpenAddFromStore(false)}>Cancel</Button>
                                    <Button onClick={(addAppsFromStore)} disabled={url === '' || (currentGroup.type === 'android' && !url.includes('play.google.com')) || (currentGroup.type === 'ios' && !url.includes('apps.apple.com'))}>Ok</Button>
                                </DialogActions>
                            </Dialog>

                            {/* Add app from package */}
                            <Dialog
                                open={openAddFromPackage}
                                fullWidth={true}
                                onClose={() => setOpenAddFromPackage(false)}
                            >
                                <DialogTitle>Enter app url</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>Enter {currentGroup.type} url</DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label={"Enter link of " + currentGroup.type + " app"}
                                        fullWidth
                                        value={url}
                                        variant="standard"
                                        type="url"
                                        onChange={(event) => setUrl(event.target.value)}
                                    />
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label={"Enter link of " + currentGroup.type + " app"}
                                        fullWidth
                                        value={url}
                                        variant="standard"
                                        type="url"
                                        onChange={(event) => setUrl(event.target.value)}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpenAddFromPackage(false)}>Cancel</Button>
                                    <Button onClick={(addAppsFromPackage)} disabled={url === '' || (currentGroup.type === 'android' && !url.includes('play.google.com')) || (currentGroup.type === 'ios' && !url.includes('apps.apple.com'))}>Ok</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    )}
                </Grid>
                <div>
                    {/* Edit group dialog */}
                    <Dialog
                        open={openEditGroup}
                        fullWidth={true}
                        onClose={() => setOpenEditGroup(false)}
                    >
                        <DialogTitle>Edit</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Edit group detail</DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Group name"
                                disabled={tempGroupData.isDefault}
                                fullWidth
                                value={tempGroupData.name}
                                variant="standard"
                                onChange={(event) => setTempGroupData({ ...tempGroupData, name: event.target.value })}
                            />
                            <FormControlLabel control=
                                {<Checkbox
                                    onChange={(e) => {
                                        if (e.target.checked) setTempGroupData({ ...tempGroupData, frequencyLimit: 60 })
                                        else setTempGroupData({ ...tempGroupData, frequencyLimit: 0 })
                                    }}
                                    checked={tempGroupData.frequencyLimit !== undefined && tempGroupData.frequencyLimit > 0} />}
                                label="Enable limit ads" />
                            {
                                tempGroupData.frequencyLimit > 0 ?
                                    <TextField
                                        margin="dense"
                                        id="name"
                                        label="Frequency limit (seconds)"
                                        fullWidth
                                        value={tempGroupData.frequencyLimit}
                                        variant="standard"
                                        type="number"
                                        onChange={(event) => setTempGroupData({ ...tempGroupData, frequencyLimit: parseInt(event.target.value) })}
                                    /> : null
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenEditGroup(false)}>Cancel</Button>
                            <Button onClick={updateGroup} disabled={tempGroupData.name === ''}>Ok</Button>
                        </DialogActions>
                    </Dialog>

                    {/* <Dialog
                        open={openChange}
                        onClose={handleChangeClose}
                    >
                        <DialogTitle>{"Change app auto update state"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {'Are you sure change anable state of ads group "' + group.name + '"?'}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleChangeClose}>No</Button>
                            <Button onClick={changeGroupEnableStatus} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog> */}

                    {/* Enable group dialog */}
                    <Dialog
                        open={openChange}
                        onClose={handleChangeClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Change confirm"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure change this group?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleChangeClose}>No</Button>
                            <Button onClick={changeGroupEnableStatus} autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>

                    {/* Add group dialog */}
                    <Dialog
                        open={openAddGroup}
                        fullWidth={true}
                        onClose={() => setOpenAddGroup(false)}
                    >
                        <DialogTitle>Add</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Add ads group</DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Group name"
                                fullWidth
                                value={tempGroupData.name}
                                variant="standard"
                                onChange={(event) => setTempGroupData({ ...tempGroupData, name: event.target.value })}
                            />
                            <TextField
                                margin="dense"
                                id="name"
                                label="Group app link"
                                fullWidth
                                value={tempGroupData.url}
                                variant="standard"
                                type="url"
                                onChange={(event) => setTempGroupData({ ...tempGroupData, url: event.target.value })}
                            />
                            <FormControlLabel control=
                                {<Checkbox
                                    onChange={(e) => {
                                        if (e.target.checked) setTempGroupData({ ...tempGroupData, frequencyLimit: 60 })
                                        else setTempGroupData({ ...tempGroupData, frequencyLimit: 0 })
                                    }}
                                    checked={tempGroupData.frequencyLimit !== undefined && tempGroupData.frequencyLimit > 0} />}
                                label="Enable limit ads" />
                            {
                                tempGroupData.frequencyLimit > 0 ?
                                    <TextField
                                        margin="dense"
                                        id="name"
                                        label="Frequency limit (seconds)"
                                        fullWidth
                                        value={tempGroupData.frequencyLimit}
                                        variant="standard"
                                        type="number"
                                        onChange={(event) => setTempGroupData({ ...tempGroupData, frequencyLimit: parseInt(event.target.value) })}
                                    /> : null
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenAddGroup(false)}>Cancel</Button>
                            <Button onClick={addGroup} disabled={tempGroupData.name === ''}>Ok</Button>
                        </DialogActions>
                    </Dialog>

                    {/* Delete group dialog */}
                    <Dialog
                        open={openDeleteGroup}
                        onClose={() => setOpenDeleteGroup(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Delete confirm"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure delete this group?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setOpenDeleteGroup(false);
                                }}
                            >
                                No
                            </Button>
                            <Button onClick={deleteGroup} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Grid>
        </React.Fragment>
    );
}