import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AdsIcon from '@mui/icons-material/AdUnits';
import UpdateIcon from '@mui/icons-material/SystemUpdate';
import StarIcon from '@mui/icons-material/Star';
import ReportIcon from '@mui/icons-material/Assessment';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import AdsGroup from './screens/AdsGroup';
import AppUpdateConfig from './screens/AppUpdateConfig';
import Reports from './screens/Reports';
import Login from './screens/Login';
import ReactDOM from 'react-dom';
import ReviewExchange from './screens/ReviewExchange';

const drawerWidth = 240;
export type Color = "inherit" | "disabled" | "action" | "primary" | "secondary" | "error" | undefined;

export default function PermanentDrawerLeft() {

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const updateCurrentIndex = () => {
    const currentIndex = localStorage.getItem('adsnetwork_index');
    if (currentIndex === '0') setSelectedIndex(0);
    if (currentIndex === '1') setSelectedIndex(1);
    if (currentIndex === '2') setSelectedIndex(2);
    if (currentIndex === '3') setSelectedIndex(3);
  };

  React.useEffect(() => {
    updateCurrentIndex();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Fox platform
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          <ListItem
            button key='ads-group-btn'
            onClick={() => {
              localStorage.setItem('adsnetwork_index', '0');
              setSelectedIndex(0);
            }}
          >
            <ListItemIcon>
              <AdsIcon color={selectedIndex === 0 ? "primary" as Color : "disable" as Color} />
            </ListItemIcon>
            <ListItemText primary="Ads group" />
          </ListItem>
          <ListItem
            button key='report-btn'
            onClick={() => {
              localStorage.setItem('adsnetwork_index', '1');
              setSelectedIndex(1)
            }}
          >
            <ListItemIcon>
              <ReportIcon color={selectedIndex === 1 ? "primary" as Color : "disable" as Color} />
            </ListItemIcon>
            <ListItemText primary="Ads Report" />
          </ListItem>
          <ListItem
            button key='app-update-btn'
            onClick={() => {
              localStorage.setItem('adsnetwork_index', '2');
              setSelectedIndex(2);
            }}
          >
            <ListItemIcon>
              <UpdateIcon color={selectedIndex === 2 ? "primary" as Color : "disable" as Color} />
            </ListItemIcon>
            <ListItemText primary="App update config" />
          </ListItem>
          <ListItem
            button key='review-btn'
            disabled
            onClick={() => {
              localStorage.setItem('adsnetwork_index', '3');
              setSelectedIndex(3);
            }}
          >
            <ListItemIcon>
              <StarIcon color={selectedIndex === 3 ? "primary" as Color : "disable" as Color} />
            </ListItemIcon>
            <ListItemText primary="Review exchange" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button key='log-out-btn'
            onClick={() => {
              localStorage.removeItem('adsnetwork_token');
              ReactDOM.render(<Login />, document.getElementById('root'));
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
        {selectedIndex === 0 ? <AdsGroup /> : selectedIndex === 1 ? <Reports /> : selectedIndex === 2 ? <AppUpdateConfig /> : <ReviewExchange />}
      </Box>
    </Box>
  );
}
