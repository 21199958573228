import React, { useState } from 'react';
import { 
    Avatar,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Typography,
    Container,
    Box
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import MusicNoteIcon from '@mui/icons-material/BarChartRounded';
import App from '../App';
import ReactDOM from 'react-dom';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Foxcode Studio
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles({
    paper: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: 4,
        backgroundColor: 'red',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: 4,
    },
    submit: {
        margin: 4
    },
});

export default function Login() {
    const classes = useStyles();

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const handleLogin = () => {
        if (formData.username === 'admin' && formData.password === 'admin') {
            localStorage.setItem('adsnetwork_token', '123');
            ReactDOM.render(<App />, document.getElementById('root'));
        } else {
            setFormData(
                {
                    username: '',
                    password: '',
                }
            )
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <MusicNoteIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Foxcode platform
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="User Name"
                        name="username"
                        autoComplete="username"
                        defaultValue={formData.username}
                        onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        defaultValue={formData.password}
                        onChange={(event) => setFormData({ ...formData, password: event.target.value })}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={formData.username !== '' && formData.password !== '' ? false : true}
                        onClick={handleLogin}
                    >
                        Sign In
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}