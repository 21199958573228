import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Fab,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TextField,
    Link,
    Snackbar,
    Alert,
    Tooltip,
    ListSubheader
} from '@mui/material';
import Switch from '@mui/material/Switch';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from "@mui/icons-material/ContentCopy";
import { Avatar, ListItemAvatar, Typography } from '@mui/material';
import axios from 'axios';
import { get } from 'lodash';

const API = 'https://adsnetwork-api.romancenovelx.com/api/v1/configs';
type Severity = "success" | "info" | "warning" | "error" | undefined;

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};

export default function ReviewExchange() {

    const [currentApp, setCurrentApp] = React.useState({
        _id: '',
        title: '',
        version: '',
        icon: '',
        storeAppId: '',
        requireUpdate: false,
        isSuspend: false,
        newApp: ''
    });
    const [appList, setAppList] = React.useState([
        {
            _id: '',
            title: '',
            version: '',
            icon: '',
            storeAppId: '',
            requireUpdate: false,
            isSuspend: false,
            newApp: ''
        }
    ])

    const [tempApp, setTempApp] = React.useState({
        _id: '',
        title: '',
        url: '',
        version: '',
        icon: '',
        storeAppId: '',
        requireUpdate: false,
        isSuspend: false,
        newApp: ''
    })

    const [openDelete, setOpenDelete] = React.useState(false);

    const handleDelete = (app: any) => () => {
        setCurrentApp(app);
        setOpenDelete(true);
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
    };

    const deleteItem = async () => {
        try {
            const newAppList = await axios.delete(API + '/' + currentApp._id)
            setAppList(newAppList.data);
            showSuccessSnackBar("Success delete item");
        } catch (err) {
            console.log(err);
            showErrorSnackBar(err);
        }
        setOpenDelete(false);
    }

    const [openChange, setOpenChange] = React.useState(false);

    const handleChange = (app: any) => () => {
        setCurrentApp(app);
        setOpenChange(true);
    };

    const handleChangeClose = () => {
        setOpenChange(false);
    };

    const changeItem = async () => {
        try {
            const newAppList = await axios.put(API + '/' + currentApp._id)
            setAppList(newAppList.data);
            showSuccessSnackBar("Success change auto update app state");
        } catch (err) {
            console.log(err);
            showErrorSnackBar(err);
        }
        setOpenChange(false);
    }

    const [openForm, setOpenForm] = React.useState(false);

    const handleClickFormOpen = () => {
        setOpenForm(true);
    };

    const createItem = async () => {
        try {
            const newAppList: any = await axios({
                method: 'post',
                url: API,
                data: {
                    url: tempApp.url
                }
            });
            setAppList(newAppList.data);
            showSuccessSnackBar("Success add item");
        } catch (err) {
            console.log("Create app error:", err);
            showErrorSnackBar(err);
        }
        setOpenForm(false);
    }

    const handleFormClose = () => {
        setOpenForm(false);
    };

    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("Success");
    const [severity, setSeverity] = React.useState("success");

    const showErrorSnackBar = (err: any) => {
        let message: string = get(err.response.data, 'message');
        message = (message && message !== '') ? message : 'Something error';
        setSeverity("error");
        setSnackBarMessage(message);
        setOpenSnackBar(true);
    }

    const showSuccessSnackBar = (message: string) => {
        setSeverity("success");
        setSnackBarMessage(message);
        setOpenSnackBar(true);
    }

    async function getAllConfig() {
        const data = await axios.get(API);
        setAppList(data.data);
    }

    React.useEffect(() => {
        getAllConfig();
    }, []);

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackBar(false)}
                    severity={severity as Severity}
                    sx={{ width: "100%" }}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <List
                sx={{ width: '100%', maxWidth: 700, bgcolor: 'background.paper' }}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Config update app via newest version on store. If app is suspended on store, redirect user download other app.
                    </ListSubheader>
                }
            >
                {appList.map((app: any) => (
                    <div key={app._id}>
                        <ListItem
                            alignItems="flex-start"
                        >
                            <ListItemAvatar>
                                <Tooltip title="Open app in new tab">
                                    <Link
                                        href={app.storeAppId.includes('.') ?
                                            'https://play.google.com/store/apps/details?id=' + app.storeAppId :
                                            'https://apps.apple.com/us/app/id' + app.storeAppId}
                                        target="_blank"
                                    >
                                        <Avatar variant="rounded" alt="icon" src={app.icon} />
                                    </Link>
                                </Tooltip>
                            </ListItemAvatar>
                            <ListItemText
                                primary={app.title}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline', fontStyle: 'italic' }}
                                            component="span"
                                            variant="caption"
                                            color="text.primary"
                                        >
                                            {app.storeAppId}
                                        </Typography>
                                        <br />
                                        <Typography
                                            sx={{ display: 'inline', fontStyle: app.isSuspend ? 'italic' : 'normal' }}
                                            component="span"
                                            variant="caption"
                                            color={app.isSuspend ? "text.secondary" : "text.primary"}
                                        >
                                            {app.isSuspend ? 'suspended' : 'version ' + app.version}
                                        </Typography>
                                        <br />
                                        {app.isSuspend ? <Tooltip title="Redirect suspended app to new app"><Link href={app.newApp} target="_blank">New app</Link></Tooltip> : undefined}
                                    </React.Fragment>
                                }
                            />
                            {/* <ListItemSecondaryAction> */}
                            <Tooltip title="Copy API to use in app">
                                <IconButton
                                    edge="end" aria-label="copy"
                                    onClick={() => {
                                        navigator.clipboard.writeText(API + '/' + app.storeAppId);
                                        showSuccessSnackBar('Copied API to clipboard!')
                                    }}
                                >
                                    <CopyIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete config">
                                <IconButton
                                    edge="end" aria-label="delete"
                                    onClick={handleDelete(app)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Change auto update state">
                                <Switch
                                    edge="end"
                                    onChange={handleChange(app)}
                                    disabled={app.isSuspend}
                                    checked={app.requireUpdate}
                                    inputProps={{
                                        'aria-labelledby': 'switch-list-label-wifi',
                                    }}
                                />
                            </Tooltip>
                            {/* </ListItemSecondaryAction> */}
                        </ListItem><Divider />
                    </div>
                ))}
            </List>
            <Fab
                sx={fabStyle} color="primary" aria-label="add"
                onClick={handleClickFormOpen}
            >
                <AddIcon />
            </Fab>

            <Dialog
                open={openDelete}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete app"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {'Are you sure delete app "' + currentApp.title + '"?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose}>No</Button>
                    <Button onClick={deleteItem} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openChange}
                onClose={handleChangeClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Change app auto update state"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {'Are you sure change auto update state of app "' + currentApp.title + '"?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleChangeClose}>No</Button>
                    <Button onClick={changeItem} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openForm} onClose={handleFormClose}>
                <DialogTitle>Add app</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add URL of app on Appstore (iOS) or GooglePlay (Android) to config auto update
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="applink"
                        label="Enter app url"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setTempApp({ ...tempApp, url: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFormClose}>Cancel</Button>
                    <Button onClick={createItem}>Add</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
