import { Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TablePaginationActions from '../components/TablePaginationActions';
import axios from 'axios';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: '#fff',
    },
});

export default function Reports() {

    const classes = useStyles();

    const [range, setRange] = useState("7");

    const handleRangeChange = (event: SelectChangeEvent) => {
        setRange(event.target.value as string);
    };

    const [chartData, setChartData] = useState([
        { date: "", request: 0, impression: 0, click: 0 },
    ]);

    const [appRows, setAppRows] = useState([
        { request: 0, impression: 0, click: 0, install: 0, storeAppId: "" }
    ])
    const [appPage, setAppPage] = useState(0);
    const [appRowsPerPage, setAppRowsPerPage] = useState(5);
    const emptyAppRows = appPage > 0 ? Math.max(0, (1 + appPage) * appRowsPerPage - appRows.length) : 0;

    const handleChangeAppPage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setAppPage(newPage);
    };

    const handleChangeAppRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setAppRowsPerPage(parseInt(event.target.value, 10));
        setAppPage(0);
    };

    //nation table
    const [countryRows, setCountryRows] = useState([
        { impression: 0, click: 0, install: 0, country: "" }
    ])
    const [countryPage, setCountryPage] = useState(0);
    const [countryRowsPerPage, setCountryRowsPerPage] = useState(5);
    const emptyCountryRows = countryPage > 0 ? Math.max(0, (1 + countryPage) * countryRowsPerPage - countryRows.length) : 0;

    const handleChangeCountryPage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCountryPage(newPage);
    };

    const handleChangeCountryRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setCountryRowsPerPage(parseInt(event.target.value, 10));
        setCountryPage(0);
    };

    const [summaryData, setSummaryData] = useState({
        today: {
            request: 0,
            impression: 0,
            click: 0,
            install: 0
        },
        yesterday: {
            request: 0,
            impression: 0,
            click: 0,
            install: 0
        },
        thisMonth: {
            request: 0,
            impression: 0,
            click: 0,
            install: 0
        },
        lastMonth: {
            request: 0,
            impression: 0,
            click: 0,
            install: 0
        }
    });

    async function getSummaryData() {
        const data = await axios.get('https://adsnetwork-api.romancenovelx.com/api/v1/reports/summary');
        setSummaryData(data.data);
    }

    async function getChartData() {
        const data = await axios.get('https://adsnetwork-api.romancenovelx.com/api/v1/reports/chart?range=' + range);
        setChartData(data.data);
    }

    async function getAppRows() {
        const data = await axios.get('https://adsnetwork-api.romancenovelx.com/api/v1/reports/app?range=' + range);
        setAppRows(data.data);
    }

    async function getCountryRows() {
        const data = await axios.get('https://adsnetwork-api.romancenovelx.com/api/v1/reports/country?range=' + range);
        setCountryRows(data.data);
    }

    useEffect(() => {
        getSummaryData();
        getChartData();
        getAppRows();
        getCountryRows();
    }, [range]);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={3} style={{ textAlign: "center" }}>
                    <b>Today so far</b><br />
                    {summaryData.today.request} request<br />{summaryData.today.impression} impression<br />{summaryData.today.click} click<br />{summaryData.today.install} install
                </Grid>
                <Grid item xs={3} style={{ textAlign: "center" }}>
                    <b>Yesterday</b><br />
                    {summaryData.yesterday.request} request<br />{summaryData.yesterday.impression} impression<br />{summaryData.yesterday.click} click<br />{summaryData.yesterday.install} install
                </Grid>
                <Grid item xs={3} style={{ textAlign: "center" }}>
                    <b>This month</b><br />
                    {summaryData.thisMonth.request} request<br />{summaryData.thisMonth.impression} impression<br />{summaryData.thisMonth.click} click<br />{summaryData.thisMonth.install} install
                </Grid>
                <Grid item xs={3} style={{ textAlign: "center" }}>
                    <b>Last month</b><br />
                    {summaryData.lastMonth.request} request<br />{summaryData.lastMonth.impression} impression<br />{summaryData.lastMonth.click} click<br />{summaryData.lastMonth.install} install
                </Grid>
            </Grid>
            <Divider />
            <Grid item xs={12}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h5" component="div">
                    Summary chart
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <Select
                        labelId="timeFilter"
                        id="timeFilter"
                        value={range}
                        onChange={handleRangeChange}
                    >
                        <MenuItem value={"7"}>Last 7 days</MenuItem>
                        <MenuItem value={"30"}>Last 30 days</MenuItem>
                        <MenuItem value={"90"}>Last 90 days</MenuItem>
                    </Select>
                    <FormHelperText>Select range</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={12}>
                <ResponsiveContainer width="100%" height={500}>
                    <LineChart
                        width={500}
                        height={300}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="request" stroke="#82ca9d" />
                        <Line type="monotone" dataKey="impression" stroke="#8884d8" />
                        <Line type="monotone" dataKey="click" stroke="#80ccc4" />
                        <Line type="monotone" dataKey="install" stroke="#80cc00" />
                    </LineChart>
                </ResponsiveContainer>
            </Grid>
            <Divider />
            <Grid item xs={12}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h5" component="div">
                    App report
                </Typography>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableBody>
                        <TableRow key="app-table-header">
                            <TableCell component="th" scope="row">
                                <b>PACKAGE NAME/BUNDLE ID</b>
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="right">
                                <b>REQUESTS</b>
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="right">
                                <b>IMPRESSIONS</b>
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="right">
                                <b>CLICKS</b>
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="right">
                                <b>INSTALLS</b>
                            </TableCell>
                        </TableRow>
                        {(appRowsPerPage > 0
                            ? appRows.slice(appPage * appRowsPerPage, appPage * appRowsPerPage + appRowsPerPage)
                            : appRows
                        ).map((row) => (
                            <TableRow key={row.storeAppId}>
                                <TableCell component="th" scope="row">
                                    {row.storeAppId}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right">
                                    {row.request}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right">
                                    {row.impression}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right">
                                    {row.click}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right">
                                    {row.install}
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyAppRows > 0 && (
                            <TableRow style={{ height: 53 * emptyAppRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={3}
                                count={appRows.length}
                                rowsPerPage={appRowsPerPage}
                                page={appPage}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'approws per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangeAppPage}
                                onRowsPerPageChange={handleChangeAppRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

            <Divider />
            <Grid item xs={12}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h5" component="div">
                    Country report
                </Typography>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableBody>
                        <TableRow key="country-table-header">
                            <TableCell component="th" scope="row">
                                <b>COUNTRY CODE</b>
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="right">
                                <b>IMPRESSIONS</b>
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="right">
                                <b>CLICKS</b>
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="right">
                                <b>INSTALLS</b>
                            </TableCell>
                        </TableRow>
                        {(countryRowsPerPage > 0
                            ? countryRows.slice(countryPage * countryRowsPerPage, countryPage * countryRowsPerPage + countryRowsPerPage)
                            : countryRows
                        ).map((row) => (
                            <TableRow key={row.country}>
                                <TableCell component="th" scope="row">
                                    {row.country}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right">
                                    {row.impression}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right">
                                    {row.click}
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right">
                                    {row.install}
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyCountryRows > 0 && (
                            <TableRow style={{ height: 53 * emptyCountryRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={3}
                                count={countryRows.length}
                                rowsPerPage={countryRowsPerPage}
                                page={countryPage}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'countryrows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangeCountryPage}
                                onRowsPerPageChange={handleChangeCountryRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}
